export default {
    nav: {
        home: 'Главная',
        login: 'Войти',
        register: 'Зарегистрироваться',
        playnow: 'Играть сейчас',
        logout: 'Выйти',
        profile: 'Профиль',
        store: 'Магазин',
        balance: 'Баланс {credites} кред.',
        forum: 'Форум',
        mainMenu: 'Главное меню',
        accountIsNotComplete: 'Ваш аккаунт заполнен не полностью. Настоятельно рекомендуем привязать почту, чтобы воспользоваться всеми возможностями сайта и игры.',
    },
    loginPage: {
        title: "Вход в аккаунт",
        useAcc: 'Для входа используйте свой аккаунт WarForGalaxy.com',
        signIn: 'Войти',
        rememberMe: "Запомнить меня",
        isForget: "Забыли пароль?",
        recover: "Восстановить аккаунт",
        isNotRegister: "Еще не зарегистрировались?",
        createAcc: "Создать аккаунт",
        signSoc: "Войдите с помощью социальной сети",
        fb: "Facebook",
        google: "Google",
        vk: "Вконтакте",
        email: "Email",
        pass: "Пароль"
    },
    registerPage: {
        title: "Зарегистрируйся и играй",
        agreeRules: "Соглашаюсь с <a href='/rules'>правилами</a>",
        agreePromo: "Хочу получать информационные сообщения",
        continue: "Продолжить",
        email: "Email",
        pass: "Пароль",
        nick: "Выберите имя в игре",
        rptPass: "Повторите пароль"
    },
    recoveryPage: {
        title: "Восстановление аккаунта",
        submitbtn: "Восстановить"
    },
    updatePassPage: {
        title: "Изменение пароль",
        pass: "Новый пароль",
        rptPass: "Повторите новый пароль",
        submitbtn: "Сохранить"
    },
    profilePage: {
        user: "Пользователь",
        credites: "Кредиты",
        pcs: "ед."
    },
    socialCompletePage: {
        title: "Завершение регистрации",
        submitbtn: "Завершить"
    },
    rulesPage: {
        title: 'Правила пользования ресурсом'
    },
    notFoundPage: {
        title: 'Страница не найдена',
        text: 'К сожалению, такой страницы нет :('
    },
    store: {
        title: 'Премиум магазин',
        packageHas: 'Набор содержит:',
        credites: '{n} кредит | {n} кредита | {n} кредитов',
        buy: 'Купить',
        balanceIsNotEnough: 'Недостаточно баланса для покупки данного товара. Пополните баланс и попробуйте снова',
        units: 'единиц',
        days: 'дней',
        geolog: 'Геолог',
        geologist: 'Геолог',
        admiral: 'Адмирал',
        professor: 'Ученый',
        engineer: 'Инженер',
        signalman: 'Связист',
        nuclear_physicist: 'Физик-ядерщик',
        foreman: 'Бригадир',
        titanium: 'Титан',
        modalSuccessTitle: 'Вы успешно приобрели товар',
        modalSuccessDesc: 'Товар приобретен, и в данный миг мы уже добавляем все улучшения на ваш аккаунт. Если возникнут проблемы с добавление смело пишите нам на <a href="mailto:support@warforgalaxy.com">support@warforgalaxy.com</a>',
        modalSuccessBtn: 'Отлично!',
        balance: 'Ваш баланс',
        refill: 'Пополнить',
        needConfirmPnumber: 'Для приобретения этого пакета вам необходимо привязать номер телефона к аккаунту в разделе «Профиль»'
    },
    refillPage: {
        title: 'Пополнение баланса',
        creditCount: 'Количество кредитов',
        paymentMethods: 'Способ оплаты (кредиты зачисляются автоматически в течении 1 минуты)',
        fee: 'Комиссия',
        refillBtn: 'Пополнить',
        successTitle: 'Оплата прошла успешно',
        successText: '',
        failTitle: 'Не удалось подтвердить оплату',
        failText: ''
    },
    purchasesPage: {
        title: 'Покупки',
        descPrefix: 'Покупка',
        date: 'Дата',
        cost: 'Стоимость',
        desc: 'Описание',
        noitems: 'Нет покупок',
        credPostfix: 'кредитов'
    }
}
