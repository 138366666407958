<template>
  <div class="row center-xs middle-xs recovery-page">
    <div class="col-xs-12 col-md-10 col-lg-6 wrapper">
      <div class="change-lang">
        <select @change="changeLocale">
          <option value="ru">RU</option>
          <option value="en">EN</option>
        </select>
      </div>
      <h1>{{ $t("updatePassPage.title") }}</h1>
      <div class="success-msg" v-if="successResult">
        <p>Ваш пароль успешно изменен.</p>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6 text-left">
          <form @submit.prevent="submitHandler">
            <input type="hidden" name="hash" :value="this.hash" required>

            <input class="input" type="password" name="password" v-model="password" :placeholder="this.$t('updatePassPage.pass')" minlength="8" required>
            <input class="input" type="password" name="confirm_password" v-model="confirmPassword" :placeholder="this.$t('updatePassPage.rptPass')" minlength="8" required>
            <div class="row error-msg" v-if="error">
              <div class="col-xs-12">
                <p>{{ error }}</p>
              </div>
            </div>
            <div class="row middle-xs">
              <div class="col-xs-6 col-md-6 col-lg-5">
                <button class="btn" type="submit">{{ $t("updatePassPage.submitbtn") }}</button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-xs-12 col-md-6">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updatePass } from '../lib/api'
import { fdToObj } from '../lib/utils'

export default {
  name: 'UpdatePassPage',
  props: ['hash'],
  data: () => {
    return {
      password: "",
      confirmPassword: "",
      error: "",
      successResult: false
    }
  },
  methods: {
    checkPasswords() {
      if (this.password && this.confirmPassword && this.password === this.confirmPassword) {
        return true
      }
      
      return false
    },
    async submitHandler(ev) {
      this.error = ''
      const form = ev.target

      if (!this.checkPasswords()) {
        this.error = "Пароли не совпадают"
        return false
      }

      const formObj = fdToObj(new FormData(form))

      const { data } = await updatePass(formObj)
      if (data.status !== 1) {
        this.setErrorMsg(data.errorid)
        return;
      }

      form.reset()

      this.successResult = true
      setTimeout(() => {
        this.successResult = false
        window.location.href = '/login'
      }, 5000)
    },
    setErrorMsg(errorid) {
      let errorMsg = ''

      switch (errorid) {
        case 1:
          errorMsg = "Неправильный логин или пароль"
          break
        default:
          errorMsg = "Техническая ошибка. Попробуйте позже"
      }

      this.error = errorMsg
    },
    changeLocale(ev) {
      this.$i18n.locale = ev.target.value
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.recovery-page {
  margin-top: 150px;
}
.wrapper {
  background: rgb(72,82,83);
  background: linear-gradient(90deg, rgba(72,82,83,1) 41%, rgba(108,116,117,0) 100%);
  border: 1px solid #717E81;
  padding: 80px 50px 20px 50px;
  position: relative;

  &:after {
    width: 97px;
    height: 118px;
    position: absolute;
    top: -48px;
    left: 50%;
    margin-left: -49px;
    background: url("~@/assets/login_logo.png") no-repeat;
    background-size: contain;
    display: block;
    content: '';
  }
}

.change-lang {
  position: absolute;
  right: 10px;
  top: 10px;

  select {
    color: #FFF;
    background: #191919;
    padding: 3px 5px
  }
}

h1 {
  font-family: "Russo One", Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

form {
  margin-bottom: 15px;
}
p {
  margin-bottom: 15px;
  display: block;
}
p.before {
  margin-top: 0px;
  margin-bottom: 16px;
}

form button {
  margin-left: 2px;
}

.soc-btns {
  a {
    text-decoration: none;
  }
}

.soc-btn {
  padding: 21px;
  padding-left: 80px;
  margin-bottom: 16px;
  position: relative;
  box-shadow: 0px 0px 5px #000;

  &:before {
    box-shadow: 0px 0px 5px #000;
    content: '';
    display: block;
    width: 63px;
    height: 63px;
    top: 0;
    left: 0;
    position: absolute;
  }

  &-fb {
    background: #5374BA;

    &:before {      
      background: url("~@/assets/fb.png") no-repeat;
    }
  }

  &-google {
    background: #659AF3;

    &:before {
      background: url("~@/assets/google.png") no-repeat;
    }
  }

  &-vk {
    background: #527DAE;

    &:before {
      background: url("~@/assets/vk.png") no-repeat;
    }
  }

  span {
    font-weight: bold;
  }
}
</style>
