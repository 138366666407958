<template>
  <div class="row center-xs middle-xs login-page">
    <div class="col-xs-12 col-md-10 col-lg-6 wrapper">
      <div class="change-lang">
        <select @change="changeLocale">
          <option value="ru">RU</option>
          <option value="en">EN</option>
        </select>
      </div>
      <h1>{{ $t("loginPage.title") }}</h1>
      <div class="row" v-if="success > 0">
        <div class="col-xs-12">
          <div class="success">Изменения успешно сохранены!</div>
        </div>
      </div>
      <div class="row" v-if="!isAuth">
        <div class="col-xs-12 col-md-6 text-left">
          <p class="before end-with-ellipsis">{{ $t("loginPage.useAcc") }}</p>
          <form @submit.prevent="submitHandler">
            <input class="input" type="text" name="login" :placeholder="this.$t('loginPage.email')" autocomplete="off" required>
            <div class="input-group">
              <input class="input" type="password" name="password" :placeholder="this.$t('loginPage.pass')" minlength="8" required>
              <span @click="showPass($event)">
                <img v-if="!isShowPassword" src="~@/assets/view.svg" alt="Show password">
                <img v-else src="~@/assets/hide.svg" alt="Hide password">
              </span>
            </div>
            <div class="row error-msg" v-if="error">
              <div class="col-xs-12">
                <p>{{ error }}</p>
              </div>
            </div>
            <div class="row middle-xs">
              <div class="col-xs-6 col-md-6 col-lg-5">
                <button class="btn" type="submit">{{ $t("loginPage.signIn") }}</button>
              </div>
              <div class="col-xs-6 col-md-6 col-lg-7">
                <input type="checkbox" name="remindme" value="true" id="rememberme-field">
                <label class="checkbox" for="rememberme-field">{{ $t("loginPage.rememberMe") }}</label>
              </div>
            </div>
          </form>
          <p>{{ $t("loginPage.isForget") }} <router-link to="/recovery">{{ $t("loginPage.recover") }}</router-link></p>
          <p>{{ $t("loginPage.isNotRegister") }} <router-link to="/register">{{ $t("loginPage.createAcc") }}</router-link></p>
        </div>
        <div class="col-xs-12 col-md-6 text-left soc-btns">
          <p class="before">{{ $t("loginPage.signSoc") }}</p>

          <!-- <a href="#">
            <div class="soc-btn soc-btn-fb">
              <span>{{ $t("loginPage.fb") }}</span>
            </div>
          </a>

          <a href="#">
            <div class="soc-btn soc-btn-google">
              <span>{{ $t("loginPage.google") }}</span>
            </div>
          </a> -->

          <a @click="socialAuth('vk')">
            <div class="soc-btn soc-btn-vk">
              <span>{{ $t("loginPage.vk") }}</span>
            </div>
          </a>

          <a @click="socialAuth('google')">
            <div class="soc-btn soc-btn-google">
              <span>{{ $t("loginPage.google") }}</span>
            </div>
          </a>

          <!-- <a @click="socialAuth('fb')">
            <div class="soc-btn soc-btn-fb">
              <span>{{ $t("loginPage.fb") }}</span>
            </div>
          </a> -->
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-xs-12 text-center">
          <p class="before end-with-ellipsis">Вы уже авторизованы. Перейти на <a href="/">главную страницу</a> <span v-if="returnTo"> или вернутся обратно на <a :href="returnTo">{{ returnTo }}</a></span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, checkToken } from '../lib/api'
import { fdToObj, saveToLS, reachGoal } from '../lib/utils'
import config from '../config'

export default {
  name: 'LoginPage',
  props: ['success', 'return'],
  data: () => {
    return {
      error: "",
      recaptchaPublicKey: config.RECAPTCHA_PUBLIC,
      isShowPassword: false
    }
  },
  async created() {
    // if (this.return) {
    //   saveToLS(config.RETURN_TOKEN_NAME, this.return)
    // }

    const userToken = window.localStorage.getItem(config.LS_TOKEN_NAME)
    if (userToken) {
      const { data } = await checkToken(userToken)

      if (data.status ===  1) {
        //const returnTo = getFromLS(config.RETURN_TOKEN_NAME)
        if (this.return) {
          //window.location.href = this.return
          return
        }

        //window.location.href = '/'
      }
    }
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuth
    },
    returnTo() {
      return this.return
    }
  },
  methods: {
      async submitHandler(ev) {
        const form = ev.target
        const formObj = fdToObj(new FormData(form))

        this.error = ''

        const { data } = await login(formObj)

        if (data.status !== 1) {
          this.setErrorMsg(data.errorid)
          return;
        }

        saveToLS(config.LS_TOKEN_NAME, data.token) 
        form.reset()

        reachGoal(config.ymCounterId, 'authAccount')

        // const returnTo = getFromLS(config.RETURN_TOKEN_NAME)
        if (this.returnTo) {
          window.location.href = this.returnTo
          return
        }

        window.location.href = '/'
      },
      setErrorMsg(errorid) {
        let errorMsg = ''

        switch (errorid) {
          case 1:
            errorMsg = "Неправильный логин или пароль"
            break
          case 6:
            errorMsg = "Неверное значение каптчи"
            break
          case 7:
            errorMsg = "Необходимо подтвердить почту. Если письмо не приходит, то проверьте раздел 'Спам'"
            break;
          default:
            errorMsg = "Техническая ошибка. Попробуйте позже"
        }

        this.error = errorMsg
      },
      changeLocale(ev) {
        this.$i18n.locale = ev.target.value
      },
      socialAuth(provider) {
        const providerConfig = config['oauth'][provider]
        const q = providerConfig['q']

        const url = providerConfig['url'] + '?' + Object.keys(q).map(function (i) { return i + '=' + encodeURIComponent(q[i]) }).join('&')

        window.location.href = url
      },
      showPass(ev) {
        const trg = ev.target
        const prevField = trg.parentElement.parentElement.querySelector('input')
        if (prevField.type === 'password') {
          prevField.type = 'text'
        } else {
          prevField.type = 'password'
        }

        this.isShowPassword = !this.isShowPassword
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-page {
  margin-top: 150px;
}
.wrapper {
  background: rgb(72,82,83);
  background: linear-gradient(90deg, rgba(72,82,83,1) 41%, rgba(108,116,117,0) 100%);
  border: 1px solid #717E81;
  padding: 80px 50px 20px 50px;
  position: relative;

  &:after {
    width: 97px;
    height: 118px;
    position: absolute;
    top: -48px;
    left: 50%;
    margin-left: -49px;
    background: url("~@/assets/login_logo.png") no-repeat;
    background-size: contain;
    display: block;
    content: '';
  }
}

.change-lang {
  position: absolute;
  right: 10px;
  top: 10px;

  select {
    color: #FFF;
    background: #191919;
    padding: 3px 5px
  }
}

h1 {
  font-family: "Russo One", Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

form {
  margin-bottom: 15px;
}
p {
  margin-bottom: 15px;
  display: block;
}
p.before {
  margin-top: 0px;
  margin-bottom: 16px;
}

form button {
  margin-left: 2px;
}

.soc-btns {
  cursor: pointer;

  a {
    text-decoration: none;
  }
}

.soc-btn {
  padding: 21px;
  padding-left: 80px;
  margin-bottom: 16px;
  position: relative;
  box-shadow: 0px 0px 5px #000;

  &:before {
    box-shadow: 0px 0px 5px #000;
    content: '';
    display: block;
    width: 63px;
    height: 63px;
    top: 0;
    left: 0;
    position: absolute;
  }

  &-fb {
    background: #5374BA;

    &:before {      
      background: url("~@/assets/fb.png") no-repeat;
    }
  }

  &-google {
    background: #659AF3;

    &:before {
      background: url("~@/assets/google.png") no-repeat;
    }
  }

  &-vk {
    background: #527DAE;

    &:before {
      background: url("~@/assets/vk.png") no-repeat;
    }
  }

  span {
    font-weight: bold;
  }
}

.success {
  padding: 10px;
  background: green;
  color: white;
  border-radius: 3px;
  margin-bottom: 1rem;
}
.input-group {
  position: relative;

  input {
    padding-right: 3rem;
  }
  
  span {
    display: block;
    position: absolute;
    top: 1rem;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    user-select: none;
    
    img {
      display: inline-block;
      height: 1.5rem;
      vertical-align: middle;
    }
  }
}
</style>
