<template>
  <div class="home-page">
    <div id="scene">
      <div class="layer layer-4" data-depth="0.01"></div>
      <div class="layer layer-1" data-depth="0.07"></div>
      <div class="layer layer-2" data-depth="0.3"></div>
      <div class="layer layer-3" data-depth="0.05"></div>
    </div>
    <div class="intro">
      <div class="center">
        <div class="logo">
            <img src="~@/assets/intro_logo.png" alt="">
        </div>
        <h1>Новая космическая ММО стратегия<br>для мудрых завоевателей</h1>
        <!-- <h2>Стань главным правителем в Галактике: просчитывай соперников, поглощай слабые миры, развивай колонии по выбранной модели в новой стратегической MMO «War for Galaxy».</h2> -->
        <button class="btn" @click="gameNow">Играть в браузере</button>
        <br><br>
        <br>
        <div>
          <a class="btn" :href="windowsLink" target="_blank" v-if="windowsLink">Клиент для Windows</a>&nbsp;
          <a class="btn" :href="androidLink" target="_blank" v-if="androidLink">Клиент для Android</a>
        </div>
      </div>
      <div class="scroll-down-arrows">
        <div class="arrow"></div>
        <div class="arrow"></div>
        <div class="arrow"></div>
      </div>
    </div>

    <div class="video">
      <div class="container">
        <h2>Стань главным правителем в Галактике: просчитывай соперников, поглощай слабые миры, развивай колонии по выбранной модели. Расширяй границы своих территорий. Увеличивай благосостояние в новой стратегической MMO «War for Galaxy».</h2>
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <iframe 
              src="https://www.youtube.com/embed/v_FP7V3M_cE?showinfo=0&rel=0&modestbranding=1&iv_load_policy=3" 
              frameborder="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen></iframe>
          </div>
          <div class="col-xs-12 col-md-6">
            <iframe 
              src="https://www.youtube.com/embed/s6shA8VUDW8?showinfo=0&rel=0&modestbranding=1&iv_load_policy=3" 
              frameborder="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen></iframe>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            
            <!-- VK Widget -->
            <!-- <div id="vk_groups"></div> -->
          </div>
        </div>
      </div>
    </div>
    
    <div class="ship">
      <div class="container">
        <h1>Будь готов ко встрече с соседями. Тебя захотят надуть, ограбить, лишить территорий. Никто не говорит, что также не сможешь поступить и ты.</h1>
        <p>
          Регистрируйся и выбирай свой путь: торговля, новые исследования, военный террор и пиратство. Тысячи игроков уже заняли свое место меж звезд. Поспеши.
        </p>
      </div>
    </div>

    <div class="intro intro-bottom">
      <div class="center">
        <div class="logo">
            <img src="~@/assets/intro_logo.png" alt="">
        </div>
        <h2>Проложи свой путь к звёздам</h2>
        <button class="btn" @click="gameNow">Играть в браузере</button>
        <br><br>
        <br>
        <div>
          <a class="btn" :href="windowsLink" target="_blank" v-if="windowsLink">Клиент для Windows</a>&nbsp;
          <a class="btn" :href="androidLink" target="_blank" v-if="androidLink">Клиент для Android</a>
        </div>
      </div>
    </div>
    <div class="footer-nav">
      <div class="container">
        <nav>
          <a href="#">ЦЕНТР ПОДДЕРЖКИ</a>
          <a href="#">ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</a>
        </nav>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #vk_groups {
    margin: 0 auto;
  }

  .btn {
    text-transform: uppercase;
    margin-top: 5px;
    text-decoration: none;
  }

  .logo {
    img {
      width: 393px;
      height: 506px;

      @media (max-width: 575.98px) {
        width: 291px;
        height: 376px;
      }
    }
  }
</style>

<script>
// @ is an alias to /src
import Parallax from 'parallax-js'
import { reachGoal } from '../lib/utils'
import { getBuildLinks } from '../lib/api'
import config from '../config'

export default {
  name: 'home',
  data() {
    return {
      windowsLink: '',
      androidLink: ''
    }
  },
  methods: {
    gameNow() {
      reachGoal(config.ymCounterId, 'clickPlayNow')

      if (this.isAuth) {
        window.location.href = '/play'
      } else {
        this.$router.push('login')
      }
    },
    downloadWindows() {
      reachGoal(config.ymCounterId, 'clickDownloadWindows')
      window.location.href = 'https://cdn.warforgalaxy.com/builds/windows/setup_galaxy.exe'
    },
    async loadBuildLinks() {
      const { data: response } = await getBuildLinks()

      if (response.status !== 1) {
        return
      }

      window.console.log(response.data.build)
      this.windowsLink = response.data.build.windows
      this.androidLink = response.data.build.android
    }
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuth
    }
  },
  mounted() {
    const scene = document.getElementById('scene')
    new Parallax(scene)

    this.loadBuildLinks().catch(window.console.log)
  
    // window.VK.Widgets.Group("vk_groups", {mode: 1}, 184238876);
  }
}
</script>
