import md5 from 'blueimp-md5'

export const fdToObj = formData => {
    const obj = {}

    for (let pair of formData.entries()) {
        obj[ pair[0] ] = pair[1]
    }

    return obj
}

export const saveToLS = (name, value) => {
    window.localStorage.setItem(name, value)
}

export const getFromLS = (name, remove = true) => {
    const r = window.localStorage.getItem(name)
    window.localStorage.removeItem(name)
    return r
}

export const preparePassword = plainPass => md5(plainPass).toUpperCase()

export const classesEndsWith = (el, search) => {
    const classAr = Array.from(el.classList);
    return classAr.filter(item => item.endsWith(search))
}

export const reachGoal = (counterId, goalName) => {
    if (typeof window.ym === 'function') {
        try {
            window.ym(counterId,'reachGoal', goalName)
            console.log('hit')
        } catch (err) {
            console.log(err)
        }
    }
}
