import Vuex from 'vuex'
import Vue from 'vue'
// import config from './config'
import { checkToken } from './lib/api'
import types from './types'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {
            loggedIn: false,
            token: null,
            id: null,
            name: 'unknown',
            email: 'unknown',
            credites: -1,
            pnumber: null
        },
        game: {
            isLoading: true
        }
    },
    getters: {
        isAuth(state) {
            return state.user.loggedIn
        }
    },
    mutations: {
        [types.UPDATE_USER](state, updates) {
            state.user = Object.assign(state.user, updates)
        },
        [types.SET_TOKEN](state, token) {
            state.user.token = token
        }
    },
    actions: {
        async [types.CHECK_TOKEN]({ commit, state }) {
            // console.log('token', state.user.token)
            let token = state.user.token
            let status = 0
            let userData = null

            if (token) {
                const { data } = await checkToken(state.user.token)
                if (data.status ===  1) {
                    status = 1
                    userData = data
                }
            }
    
            if (status && userData) {
                return commit(types.UPDATE_USER, {
                    loggedIn: true,
                    name: userData.name,
                    id: userData.id,
                    email: userData.email,
                    credites: userData.credites,
                    pnumber: userData.pnumber
                })
            }

            //window.localStorage.setItem(config.LS_TOKEN_NAME, '')
            return commit(types.UPDATE_USER, {
                loggedIn: false,
                name: 'unknown',
                id: null,
                email: 'unknown',
                token: null,
                credites: -1,
                pnumber: null
            })
        }
    }
})
