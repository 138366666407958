import axios from 'axios'
import config from '../config'
import { preparePassword } from './utils'

const req = axios.create({
    baseURL: config.API_URL,
    withCredentials: true
})

export const login = ({ login, password, remindme, 'g-recaptcha-response': captcha }) => {
    return req.post('/user/login', { login, remindme, captcha, password: preparePassword(password) })
}

export const register = ({ name, email, password, 'g-recaptcha-response': captcha }) => {
    return req.post('/user/register', { name, email, captcha, password: preparePassword(password) })
}

export const recovery = ({ email, 'g-recaptcha-response': captcha }) => {
    return req.post('/user/recovery', { email, captcha })
}

export const updatePass = ({ hash, password }) => {
    return req.post('/user/update-pass', { hash, password: preparePassword(password) })
}

export const checkToken = token => {
    return req.post('/user/verify', { token })
}

export const changePass = ({ token, password }) => {
    return req.post('/user/change-pass', { token, password: preparePassword(password) })
}

export const changeEmail = ({ token, email }) => {
    return req.post('/user/change-email', { token, email })
}

export const revokeToken = ({ token, revoke = null }) => {
    return req.post('/user/revoke-token', { token, revoke })
}

export const socialComplete = ({ hash, name }) => {
    return req.post('/user/social-complete', { hash, name })
}

export const getProducts = () => {
    return req.get('/store')
}

export const buyProduct = ({ token, productId }) => {
    return req.post('/store/buy', { token, productId })
}

export const getPaySystems = () => {
    return req.get('/pay/pslist')
}

export const createBill = ({ token, amount, paysys }) => {
    return req.post('/pay/invoice', { token, credites: amount, psid: paysys })
}

export const getPurchases = ({ token }) => {
    return req.post('/user/purchases', { token })
}

export const sendCode = ({ token, pnumber }) => {
    return req.post('/user/sendcode', { token, pnumber })
}

export const verifyCode = ({ token, pnumber, taskid, code }) => {
    return req.post('/user/verifycode', { token, pnumber, taskid, code })
}

export const inVacation = ({ token }) => {
    return req.post('/user/invacation', { token })
}

export const getBuildLinks = () => {
    return req.get('/settings/builds')
}

export default req
