import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './locales/index'

Vue.use(VueI18n)

export default new VueI18n({
    locale: 'ru',
    fallbackLocale: 'en',
    messages,
    pluralizationRules: {
        'ru': function (choice, choicesLength) {
            if (choice === 0) {
                return 2
            }

            const endsWith = choice % 10
            const teen = choice > 10 && choice < 20
            if (!teen) {
                if (endsWith === 1) return 0
                if (endsWith > 1 && endsWith < 5) return 1
            }

            return 2
        }
    }
})
