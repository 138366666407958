import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import Register from './views/Register.vue'
import RecoveryPass from './views/RecoveryPass.vue'
import ChangePass from './views/ChangePass.vue'
import Logout from './views/Logout.vue'
import Profile from './views/Profile.vue'
import SocialComplete from './views/SocialComplete.vue'
import Rules from './views/Rules.vue'
import NotFound from './views/NotFound.vue'
import Store from './views/Store.vue'
import Refill from './views/Refill.vue'
import RefillSuccess from './views/RefillSuccess.vue'
import RefillFail from './views/RefillFail.vue'
import Purchases from './views/Purchases.vue'

import Simulator from './views/Simulator.vue'

import { classesEndsWith } from './lib/utils'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      props: route => ({ success: route.query.success, return: route.query.return })
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/recovery',
      name: 'recovery',
      component: RecoveryPass
    },
    {
      path: '/change-pass',
      name: 'change-pass',
      component: ChangePass,
      props: route => ({ email: route.query.email, hash: route.query.hash })
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile
    },
    {
      path: '/social-complete',
      name: 'social-complete',
      component: SocialComplete,
      props: route => ({ hash: route.query.hash, token: route.query.token })
    },
    {
      path: '/simulator',
      name: 'simulator',
      component: Simulator
    },
    {
      path: '/rules',
      name: 'rules',
      component: Rules
    },
    {
      path: '/store',
      name: 'store-index',
      component: Store
    },
    {
      path: '/refill',
      name: 'refill',
      component: Refill
    },
    {
      path: '/refill/success',
      name: 'refillsuccess',
      component: RefillSuccess
    },
    {
      path: '/refill/fail',
      name: 'refillfail',
      component: RefillFail
    },
    {
      path: '/purchases',
      name: 'purchases',
      component: Purchases
    },
    {
      path: '*',
      name: 'notfound',
      component: NotFound
    }
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }
  ]
})

router.afterEach(to => {
  if (to.name) {
    const classes = classesEndsWith(document.body, '-page')
    document.body.classList.remove(...classes)
    document.body.classList.add(to.name + '-page')
  }
})

export default router
