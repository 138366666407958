<template>
  <div id="app">
    <transition name="fade">
      <loader v-if="$root.loading" />
    </transition>
    <div id="header">
      <div class="warning-message" v-if="isWithoutEmail">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <p class="text-center">{{ $t('nav.accountIsNotComplete') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="header-bg">
        <div class="container">
          <div class="row"> 
            <div class="col-xs-12">
              <a @click.prevent="toggleNav" class="toggler"><span>{{ $t('nav.mainMenu') }}</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 384 270"><path xmlns="http://www.w3.org/2000/svg" d="M368 154.668H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 32H16C7.168 32 0 24.832 0 16S7.168 0 16 0h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 277.332H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0" fill="#fff" data-original="#000000"/></svg></a>
              <nav :class="{ 'opened': this.isOpenedNav }" @click="hideNav()">
                <router-link to="/">{{ $t('nav.home') }}</router-link>
                <a href="https://forum.warforgalaxy.com/">{{ $t('nav.forum') }}</a>
                <a @click="gameNow">{{ $t('nav.playnow') }}</a>
                <router-link to="/store">{{ $t('nav.store') }}</router-link>
                <template v-if="isAuth">
                  <a href="https://cc.warforgalaxy.com/">Командный центр</a>
                  <a href="#" class="item-with-sub" @click.self="toggleMenuItem($event)">{{ username }}&nbsp;&#9660;
                    <ul>
                      <!-- <li><router-link to="/refill">{{ $t('nav.balance', { credites: this.credites }) }}</router-link></li> -->
                      <li><router-link to="/profile">{{ $t('nav.profile') }}</router-link></li>
                      <li><router-link to="/logout">{{ $t('nav.logout') }}</router-link></li>
                    </ul> 
                  </a>
                </template>
                <template v-else>
                  <router-link to="/login">{{ $t('nav.login') }}</router-link>
                  <router-link to="/register">{{ $t('nav.register') }}</router-link>
                </template>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- header -->

    <div id="main">
      <router-view :isauth="isAuth"/>
    </div>
    <notifications position="bottom right" />
  </div>
</template>

<style lang="scss">
@import "./vendor/reset.scss";
@import "./vendor/flexboxgrid.scss";
@import "./vendor/common.scss";

nav a {
  cursor: pointer;
}
</style>

<script>
import types from './types'
import loader from './components/loader'
import { reachGoal } from './lib/utils'
import config from './config'

export default {
  name: 'app',
  components: {
    loader
  },
  data() {
    return {
      isOpenedNav: false,
      isLoading: true
    }
  },
  methods: {
    toggleNav() {
      this.isOpenedNav = !this.isOpenedNav
    },
    hideNav() {
      this.isOpenedNav = false
    },
    toggleMenuItem(ev) {
      ev.stopPropagation()
      ev.target.classList.toggle('opened')
    },
    gameNow() {
      reachGoal(config.ymCounterId, 'clickPlayNow')

      if (this.isAuth) {
        window.location.href = '/play'
      } else {
        this.$router.push('login')
      }
    }
  },
  created() {
    const token = window.localStorage.getItem('glxtoken')
    if (token) {
      this.$store.commit(types.SET_TOKEN, token)
    }

  },
  async mounted() {
    await this.$store.dispatch(types.CHECK_TOKEN)
    this.$root.loading = false

    window.addEventListener('click', function (ev) {
      if(!ev.target.classList.contains('opened')) {
        document.querySelectorAll('nav .opened').forEach(el => el.classList.remove('opened'))
      }
    })
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuth
    },
    isWithoutEmail() {
      return this.$store.getters.isAuth && !this.$store.state.user.email
    },
    username() {
      return this.$store.state.user.name
    },
    credites() {
      return this.$store.state.user.credites
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log(this)
    this.isOpenedNav = false
    next()
  }
}
</script>
