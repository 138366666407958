<template>
  <div class="container">
    <div class="row center-xs middle-xs login-page">
      <div class="col-xs-12 wrapper text-left">
        <h1>{{ $t('refillPage.title') }}</h1>
        <form @submit.prevent="onSubmitHandler($event)">
          <div class="row">
            <div class="col-xs-12 amount-wrapper">
              <h3>{{ $t('refillPage.creditCount') }}</h3>
              <input class="input" type="number" name="amount" min="1" max="10000" value="1" required>
            </div>
            <div class="col-xs-12">
              <h3>{{ $t('refillPage.paymentMethods') }}</h3>
              <div class="row">

                <div
                  class="col-lg-4"
                  v-for="p in paySys"
                  :key="'ps_' + p.id">
                  <label class="paysystem">
                    <div class="checker-block">
                      <input type="radio" name="paysys" :value="p.id">
                      <span class="custom-checker"></span>
                    </div>
                    <div class="text-block">
                      <span class="title">{{ p.name }}</span>
                      <span>{{ p.desc }}</span>
                      <span class="desc">{{ $t('refillPage.fee') }} {{ p.fee }}%</span>
                    </div>
                  </label>
                </div>
                
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 pay-btn">
              <button class="btn" type="submit">{{ $t('refillPage.refillBtn') }}</button>
            </div>
          </div>
        </form>
        <form ref="payInvoiceForm" :action="hiddenForm.url" :method="hiddenForm.method">
          <input type="hidden" v-for="i in hiddenForm.inputs" :key="i.name" :name="i.name" :value="i.value">
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getPaySystems, createBill } from '../lib/api'
import { fdToObj } from '../lib/utils'

export default {
  name: 'StoreIndex',
  data: () => {
    return {
      paySys: [],
      hiddenForm: {}
    }
  },
  async created() {
    this.paySys = await this.getPaySystems()
  },
  methods: {
    async getPaySystems() {
      const { data: res } = await getPaySystems()
      
      
      return res.data
    },
    async onSubmitHandler(ev) {
      if (!this.isAuth) {
        window.location.href = `/login?return=${window.encodeURIComponent('/store')}`
        return;
      }

      const formData = new FormData(ev.target)
      formData.append('token', this.userToken)

      const { data: res } = await createBill(fdToObj(formData))

      if (res.status !== 1) {
        this.$notify({ type: 'error', text: res.errormsg })
        return;
      }

      this.hiddenForm = res.data
      this.$nextTick(() => this.$refs.payInvoiceForm.submit())
    }
  },
  computed: {
    userToken() {
      return this.$store.state.user.token
    },
    isAuth() {
      return this.$store.getters.isAuth
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-page {
  margin-top: 100px;
}

h3 {
  margin-bottom: 10px;
}
// .paysystem-wrapper {
//   padding: 0 5px
// }

.paysystem {
  display: block;
  width: 100%;
  border: 1px solid #838E8D;
  padding: 10px 10px;
  display: flex;
  background: #191919;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;

  .checker-block {
    width: 30px;

    input[type="radio"] {
      display: none;
    }

    .custom-checker {
      border: 1px solid #fff;
      width: 21px;
      height: 21px;
      display: block;
      border-radius: 50%;
      position: relative;

      &:after {
        width: 13px;
        height: 13px;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 3px;
        display: none;
        content: ''
      }
    }

    input[type="radio"]:checked ~ .custom-checker:after {
      display: block;
    }
  }

  .text-block {
    span {
      display: block;
    }

    .title {
      font-size: 1.3rem;
      font-weight: bold;
    }
  }
}

.pay-btn {
  margin-top: 20px;
}

.amount-wrapper {
  input {
    max-width: 200px;
    padding: 5px 10px;
    color: #fff;
  }
}
</style>
