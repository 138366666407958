<template>
    <span>{{ minutes | zeroPads }}:{{ seconds | zeroPads }}</span>
</template>

<script>
export default {
    name: 'countdown',
    props: ['end'],
    data: function() {
        return {
            now: Math.trunc(Date.now() / 1000),
            date: null,
            diff: 0
        }
    },
    computed: {
        minutes() {
            return Math.trunc(~~(this.diff / 60))
        },
        seconds() {
            return Math.trunc(this.diff % 60);
        }
    },
    watch: {
        now(val) {
            this.diff = this.date - this.now;

            if(this.diff <= 0) {
                this.diff = 0;
                clearTimeout(this.timer);
                this.$emit('onTimerExpire');
            }
        },
        end() {
            this.date = Math.trunc(this.end / 1000);
        }
    },
    created() {
        this.date = Math.trunc(this.end / 1000);

        this.next();
    },
    methods: {
        next() {
            this.timer = setTimeout(this.tick.bind(this), 1000);
        },
        tick() {
            this.now = Math.trunc(Date.now() / 1000);
            this.next()
        },
    },
    filters: {
        zeroPads(value) {
            if(typeof value !== 'number') value = parseInt(value, 10);
            return value < 10 ? ('0' + value) : value;
        }
    },
    destroyed() {
        clearTimeout(this.timer);
    }
}
</script>
