<template>
  <div class="row center-xs middle-xs recovery-page soc-compl-page">
    <Loader v-if="isPreloading" />
    <div class="col-xs-12 col-md-10 col-lg-6 wrapper">
      <div class="change-lang">
        <select @change="changeLocale">
          <option value="ru">RU</option>
          <option value="en">EN</option>
        </select>
      </div>
      <h1>{{ $t("socialCompletePage.title") }}</h1>
      <div class="success-msg" v-if="successResult">
        <p>.</p>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6 text-left">
          <form @submit.prevent="submitHandler">
            <input type="hidden" name="hash" :value="this.hash" required>

            <input class="input" type="text" name="name" v-model="login" :placeholder="this.$t('registerPage.nick')" autocomplete="off" minlength="5" maxlength="60" required>
            <div class="row error-msg" v-if="error">
              <div class="col-xs-12">
                <p>{{ error }}</p>
              </div>
            </div>
            <div class="row middle-xs">
              <div class="col-xs-6 col-md-6 col-lg-5">
                <button class="btn" type="submit">{{ $t("socialCompletePage.submitbtn") }}</button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-xs-12 col-md-6">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { socialComplete } from '../lib/api'
import { fdToObj, saveToLS, reachGoal, getFromLS } from '../lib/utils'
import config from '../config'
import Loader from '../components/loader'

export default {
  name: 'SocialCompletePage',
  props: ['hash', 'token'],
  components: { Loader },
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     if (vm.token) {
  //       window.localStorage.setItem('glxtoken', vm.token)
  //       // saveToLS(config.LS_TOKEN_NAME, vm.token)
  //       //console.log(window.localStorage.getItem(config.LS_TOKEN_NAME))

  //       // return setTimeout(() => {
  //       //   //window.location.href = '/'
  //       // }, 1000)
  //     }

  //     return true
  //   })
  // },
  data: () => {
    return {
      error: "",
      successResult: false,
      isPreloading: true,
      login: ''
    }
  },
  async mounted() {
    if (!this.token && !this.hash) {
      return window.location.href = '/'
    }

    if (this.token) {
      await this.wait(1500)
      saveToLS(config.LS_TOKEN_NAME, this.token)

      const returnTo = getFromLS(config.RETURN_TOKEN_NAME)
      if (returnTo) {
        window.location.href = returnTo
        return
      }

      return window.location.href = '/'
    }

    this.isPreloading = false
  },
  methods: {
    async wait(ms) {
      return new Promise(res => setTimeout(res, ms))
    },
    checkLogin(login) {
      return login.length > 4 && login.length < 61 & /^[A-Za-zА-Яа-я0-9_]+$/.test(login)
    },
    async submitHandler(ev) {
      this.error = ''
      const form = ev.target

      const formObj = fdToObj(new FormData(form))

      if (!this.checkLogin(this.login)) {
        this.error = "Логин некорректный. Можно использовать только буквы, цифры и _"
        return false
      }

      const { data } = await socialComplete(formObj)
      if (data.status !== 1) {
        this.setErrorMsg(data.errorid)
        return;
      }

      saveToLS(config.LS_TOKEN_NAME, data.token) 
      form.reset()

      reachGoal(config.ymCounterId, 'createAccountSocial')

      const returnTo = getFromLS(config.RETURN_TOKEN_NAME)
      if (returnTo) {
        window.location.href = returnTo
      } else {
        window.location.href = '/'
      }


      // this.successResult = true
      // setTimeout(() => {
      //   this.successResult = false
      //   window.location.href = '/login'
      // }, 5000)
    },
    setErrorMsg(errorid) {
      let errorMsg = ''

      switch (errorid) {
        case 1:
          errorMsg = "Неправильный логин или пароль"
          break
        case 2:
          errorMsg = "Пользователь с таким именем уже существует. Выберите другое имя игрока"
          break
        default:
          errorMsg = "Техническая ошибка. Попробуйте позже"
      }

      this.error = errorMsg
    },
    changeLocale(ev) {
      this.$i18n.locale = ev.target.value
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.recovery-page {
  margin-top: 150px;
}
.wrapper {
  background: rgb(72,82,83);
  background: linear-gradient(90deg, rgba(72,82,83,1) 41%, rgba(108,116,117,0) 100%);
  border: 1px solid #717E81;
  padding: 80px 50px 20px 50px;
  position: relative;

  &:after {
    width: 97px;
    height: 118px;
    position: absolute;
    top: -48px;
    left: 50%;
    margin-left: -49px;
    background: url("~@/assets/login_logo.png") no-repeat;
    background-size: contain;
    display: block;
    content: '';
  }
}

.change-lang {
  position: absolute;
  right: 10px;
  top: 10px;

  select {
    color: #FFF;
    background: #191919;
    padding: 3px 5px
  }
}

h1 {
  font-family: "Russo One", Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

form {
  margin-bottom: 15px;
}
p {
  margin-bottom: 15px;
  display: block;
}
p.before {
  margin-top: 0px;
  margin-bottom: 16px;
}

form button {
  margin-left: 2px;
}

.soc-btns {
  a {
    text-decoration: none;
  }
}

.soc-btn {
  padding: 21px;
  padding-left: 80px;
  margin-bottom: 16px;
  position: relative;
  box-shadow: 0px 0px 5px #000;

  &:before {
    box-shadow: 0px 0px 5px #000;
    content: '';
    display: block;
    width: 63px;
    height: 63px;
    top: 0;
    left: 0;
    position: absolute;
  }

  &-fb {
    background: #5374BA;

    &:before {      
      background: url("~@/assets/fb.png") no-repeat;
    }
  }

  &-google {
    background: #659AF3;

    &:before {
      background: url("~@/assets/google.png") no-repeat;
    }
  }

  &-vk {
    background: #527DAE;

    &:before {
      background: url("~@/assets/vk.png") no-repeat;
    }
  }

  span {
    font-weight: bold;
  }
}
</style>
